import { authHeader, guestHeader, ApiConfigs } from '../_helpers';

export const AuthService = {
    login,
    clientlogin,
    logout
};

async function login(username, password) {
    let data = {
        username: username,
        password: password
    }

    const requestOptions = {
        method: 'POST',
        headers: { ...guestHeader(), 'Content-Type': 'application/json' },
        body: JSON.stringify(data)
    };
    return await fetch(`${ApiConfigs.base_url+ApiConfigs.auth.login}`, requestOptions).then(handleResponse);
}

async function clientlogin(data) {
    const requestOptions = {
        method: 'POST',
        headers: { ...authHeader(), 'Content-Type': 'application/json' },
        body: JSON.stringify(data)
    };
    return await fetch(`${ApiConfigs.base_url+ApiConfigs.auth.clientlogin}`, requestOptions).then(handleResponse);
}

async function logout(visit_id) {
    let data = {
        visit_id: visit_id
    }
    const requestOptions = {
        method: 'POST',
        headers: { ...authHeader(), 'Content-Type': 'application/json' },
        body: JSON.stringify(data)
    };

    return await fetch(`${ApiConfigs.base_url + ApiConfigs.auth.logout}`, requestOptions)
        .then(handleResponse);
}

function handleResponse(response) {
    return response.text().then(text => {
        const data = text && JSON.parse(text);
        if (!response.ok) {
            if (response.status === 401) {
                window.location.href = '/login'
            }

            const error = (data && data.message) || response.statusText;
            return Promise.reject(error);
        }

        return data;
    });
}