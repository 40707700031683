import React ,{useEffect,useState}from 'react';
import AnimatedPages from '../components/AnimatedPages';
import { useNavigate } from 'react-router';

const ReceiveGift = ({ data }) => {

  const navigate = useNavigate();
  const [kioskpurchased, setKioskPurchased] = useState(null);

  useEffect(() => {
    const storedPhone = localStorage.getItem('kiosk_purchased_item');
    if (storedPhone) {
      setKioskPurchased(JSON.parse(storedPhone));
    }
  }, [])

  const {
    screen5_title_1,
    screen5_sub_title,
    screen5_button_text,
    screen5_image_1: { src, alt },
  } = data;

  const {
    image,
  } = kioskpurchased || {} ;

  const handleRoute = () => {
    navigate('/welcome');
  };

  return (
    <AnimatedPages>
      <section className='flex items-center justify-around h-screen overflow-hidden px-9'>
        <div className='w-[18rem] h-[20rem] lg:w-[30rem] lg:h-[33rem] px-2'>
          <img
            className='object-cover w-full h-full shadow-2xl shadow-black rounded-xl'
            src={image}
            alt={alt}
          />
        </div>
        <div className='space-y-4'>
          <h1 className='max-w-6xl text-5xl font-bold lg:text-6xl'>
            {screen5_title_1}
          </h1>
          <p className='max-w-lg text-2xl'>Tu vas recevoir un SMS. Montre-le au vendeur pour qu'il te récompenser !</p>
          <button
            className={`bg-button text-buttonText uppercase px-8 py-3 rounded-full text-2xl`}
            onClick={handleRoute}
          >
            {screen5_button_text}
          </button>
        </div>
      </section>
    </AnimatedPages>
  );
};

export default ReceiveGift;
