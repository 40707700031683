import React, { useEffect,useState} from 'react';
import Confetti from 'react-confetti';
import { motion } from 'framer-motion';
import { useNavigate } from 'react-router';
import AnimatedPages from '../components/AnimatedPages';
import { WheelGameService } from '../_services/wheel-game.service';

const Points = ({ data }) => {
  const navigate = useNavigate();

  const [kioskUser, setKioskUser] = useState(null);
  useEffect(() => {
    const storedUser = localStorage.getItem('kiosk_tablette');
    if (storedUser) {
      setKioskUser(JSON.parse(storedUser));
    }
  }, []);
  
  const {screen_win_title,screen_win_subtitle}=kioskUser?.tablette_settings || {};

  useEffect(() => {
    const interval = setInterval(() => {
      navigate('/welcome');
    }, 4000);
    return () => clearInterval(interval);
  }, []);

  const [kioskpoint, setKioskPoints] = useState(null);
  const [tabletteClient, setTabletteClient] = useState(null);

  useEffect(() => {
    const storedPhone = localStorage.getItem('client_new_points');
    if (storedPhone) {
      setKioskPoints(JSON.parse(storedPhone));
    }

    const client = localStorage.getItem('tablette_client');
    if (client) {
      setTabletteClient(JSON.parse(client));
    }
  }, []);

  const displayPoints = kioskpoint ?? 0;


  return (
    <AnimatedPages>
      <section>
        <Confetti />
        <div className='flex flex-col items-center justify-center h-screen space-y-4 text-text-main'>
          {
            displayPoints > 0 ?
              <>
                <h1 className='font-bold text-7xl'>{screen_win_title}</h1>
                <motion.p
                  initial={{ opacity: 0, scale: 0 }}
                  animate={{ opacity: 1, scale: 1 }}
                  transition={{ type: 'spring', delay: 0.3 }}
                  className='font-bold text-8xl text-primary'
                >
                  {displayPoints} points
                </motion.p>
                <p className='text-4xl font-light font-bold text-screen3SubTitle'>
                  {screen_win_subtitle}
                </p>
              </>
            :
              <>
                <h1 className='font-bold text-7xl'>Hey {tabletteClient?.name ?? ''}!</h1>
                <motion.p
                  initial={{ opacity: 0, scale: 0 }}
                  animate={{ opacity: 1, scale: 1 }}
                  transition={{ type: 'spring', delay: 0.3 }}
                  className='font-bold text-8xl text-primary'
                >
                  Bienvenue chez toi!
                </motion.p>
                <p className='text-4xl font-light font-bold text-screen3SubTitle'>
                  Nous vous souhaitons le meilleur !
                </p>
              </>
          }
          
        </div>
      </section>
    </AnimatedPages>
  );
};

export default Points;
