import React, { useState } from 'react';
import { useFormik } from 'formik';
import * as Yup from 'yup';
// import { useAuth } from '../hooks/useAuth';
import AnimatedPages from '../components/AnimatedPages';
import { AuthService } from './../_services/auth.service';
import { useNavigate } from 'react-router-dom';
import CircularProgress from '@material-ui/core/CircularProgress';
import { makeStyles } from '@material-ui/core/styles';
import Backdrop from '@material-ui/core/Backdrop';

const useStyles = makeStyles((theme) => ({
  backdrop: {
    zIndex: theme.zIndex.drawer + 1,
    color: '#fff',
  },
}));

const Login = ({ data }) => {
  
  const classes = useStyles();

  // const { login } = useAuth();
  const navigate = useNavigate();
  
  const { login_button_text, login_password, login_username } = data;
  const formik = useFormik({
    initialValues: {
      username: '',
      password: '',
    },
    validationSchema: Yup.object({
      username: Yup.string().required('Required'),
      password: Yup.string().required('Required'),
    }),
    onSubmit: (values) => {
      // login(values);
    },
  });

  const [loading, setLoading] = useState(false);
  const handleClick = () => {
    setLoading(true);

    AuthService.login(formik.values.username, formik.values.password)
      .then((response) => {
        localStorage.setItem('kiosk_tablette_token', response.token);
        localStorage.setItem('kiosk_tablette', JSON.stringify(response.tablette));
        navigate('/');
      })
      .catch((error) => {
        console.error('An error occurred:', error);

        if (
          error.response &&
          error.response.data &&
          error.response.data.message
        ) {
          alert(error.response.data.message);
        } else {
          alert('An error occurred during login. Please try again.');
        }
      })
      .finally(() => {
        setLoading(false);
      });
  };

  return (
    <AnimatedPages>
      <section className='flex items-center justify-center h-screen'>
        <div className='shadow-black/40 shadow-xl rounded-xl w-[clamp(50%,50rem,90%)] p-8 '>
          <div className='mx-auto mb-5 w-36 h-36'>
            <img
              src='images/logo.png'
              alt='login'
              className='object-contain w-full h-full'
            />
          </div>
          <form onSubmit={formik.handleSubmit} className='space-y-4'>
            <div>
              <label htmlFor='username'>{login_username}</label>
              <input
                id='username'
                name='username'
                type='text'
                onChange={formik.handleChange}
                onBlur={formik.handleBlur}
                value={formik.values.username}
                className={`w-full px-2 py-4 my-2  border outline-none rounded-xl ${
                  formik.touched.username && formik.errors.username
                    ? 'border-red-700'
                    : 'border-gray-500'
                } `}
              />
              {formik.touched.username && formik.errors.username && (
                <div className='text-red-700'>{formik.errors.username}</div>
              )}
            </div>

            <div>
              <label htmlFor='password'>{login_password}</label>
              <input
                id='password'
                name='password'
                type='password'
                onChange={formik.handleChange}
                onBlur={formik.handleBlur}
                value={formik.values.password}
                className={`w-full px-2 py-4 my-2  border outline-none rounded-xl ${
                  formik.touched.password && formik.errors.password
                    ? 'border-red-700'
                    : 'border-gray-500'
                } `}
              />
              {formik.touched.password && formik.errors.password && (
                <div className='text-red-700'>{formik.errors.password}</div>
              )}
            </div>
            <div className='flex justify-center'>
              <button
                type='button'
                className={`bg-primary text-buttonText uppercase px-8 py-3 rounded-full text-xl  w-2/3 mt-4`}
                onClick={handleClick}
                disabled={loading}
              >
                {loading ? 'Loading...' : login_button_text}
              </button>
            </div>
          </form>
        </div>
      </section>
      {loading && (
        <Backdrop className={classes.backdrop} open>
           <CircularProgress color='inherit' />
        </Backdrop>
        )}
    </AnimatedPages>
  );
};

export default Login;
