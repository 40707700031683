import React, { useState, useEffect } from 'react';
import { motion } from 'framer-motion';
import Backdrop from './Backdrop';
import { ParcoursCrmService } from './../_services/parcour-crm.service';
import CircularProgress from '@material-ui/core/CircularProgress';
import { makeStyles } from '@material-ui/core/styles';
import { useNavigate } from 'react-router';

const dropIn = {
  hidden: {
    y: '100vh',
  },
  visible: {
    y: '0',

    transition: {
      duration: 0.8,
      type: 'spring',
    },
  },
  exit: {
    y: '100vh',
  },
};

const useStyles = makeStyles((theme) => ({
  backdrop: {
    zIndex: 9999999,
    color: '#fff',
  }
}));

const ParcoursCrm = ({ partner_client_id, parcoursCrmData, handleClose }) => {
  const classes = useStyles();
  const navigate = useNavigate();

  const [kioskUser, setKioskUser] = useState(null);
  useEffect(() => {
    const storedUser = localStorage.getItem('kiosk_tablette');
    if (storedUser) {
      setKioskUser(JSON.parse(storedUser));
    }
  }, []);

  const { color_primary, color_secondary } = kioskUser?.tablette_settings || {};

  const [ParcourCrmValue, setParcourCrmValue] = useState(null);
  const [Loading, setLoading] = useState(false);

  const handleSubmit = (e) => {
    e.preventDefault()
    setLoading(true)
    let data = {
      partner_client_id: partner_client_id,
      parcours_crm_object: parcoursCrmData,
      parcours_crm_value: ParcourCrmValue,
    }

    ParcoursCrmService.setData(data).then(res => {
      let user = localStorage.getItem('tablette_client')
      user = JSON.parse(user)
      user.points = res.total_points
      localStorage.setItem('tablette_client', JSON.stringify(user))

      if( res?.new_points > 0 ){
        localStorage.setItem('client_new_points', res.new_points)
        navigate('/points');
      }

      handleClose()
      localStorage.removeItem('parcours_crm')
    }).finally(() => {
      setLoading(false)
    })
  };

  return (
    <>
     
      <Backdrop onClick={handleClose}>
        <motion.div
          onClick={(e) => e.stopPropagation()}
          className='w-[clamp(50%,700px,90%)] h-[min(50%,400px)] mx-auto rounded-xl flex flex-col items-center bg-white'
          variants={dropIn}
          initial={'hidden'}
          animate={'visible'}
          exit={'exit'}
        >
          {
            Loading &&
              <Backdrop className={classes.backdrop} open>
                <CircularProgress color='inherit' />
              </Backdrop>
          }
          <form onSubmit={handleSubmit} className='w-[clamp(100%,700px,90%)] h-[min(100%,400px)] mx-auto rounded-xl flex flex-col items-center bg-white'>
            <img src={'/images/data.png'} className='mt-2' style={{ height: '120px' }} />
            <h1 className='mt-2 mb-2 text-center text-2xl font-bold md:text-3xl'>Hey!</h1>
            <p className='max-w-lg text-lg text-center'>{parcoursCrmData?.label_message}</p>
            <div className='w-full px-5'>
              {
                parcoursCrmData.type == 'gender'?
                  <>
                    <div className='flex align-center justify-around mt-5'>
                      <div>
                        <input 
                          id='Male'
                          type='radio'
                          value={'Male'}
                          name='gender'
                          onChange={(e) => setParcourCrmValue(e.target.value)}
                          checked={ParcourCrmValue == 'Male'}
                          className='mr-2 w-4 h-4'
                          required
                        />
                        <label htmlFor='Male' className='text-2xl'>Homme</label>
                      </div>
                      <div>
                        <input 
                          id='Female'
                          type='radio'
                          value={'Female'}
                          name='gender'
                          onChange={(e) => setParcourCrmValue(e.target.value)}
                          checked={ParcourCrmValue == 'Female'}
                          className='mr-2 w-4 h-4'
                          required
                        />
                        <label htmlFor='Female' className='text-2xl'>Femme</label>
                      </div>
                    </div>
                  </>
                :
                  <input
                    type={parcoursCrmData.type == 'birth_date'? 'date' : 'text'}
                    className='text-center w-full px-4 py-2 mt-4 text-lg border border-gray-400 rounded-md focus:outline-none focus:ring-2 focus:ring-primary focus:border-transparent'
                    placeholder='Entrez ici'
                    value={ParcourCrmValue}
                    onChange={(e) => setParcourCrmValue(e.target.value)}
                    required
                    style={{ marginBottom: '20px' }}
                  />
              }
              
            </div>
            <div className='flex items-end w-full h-full'>
              <button
                className='w-full py-4 bg-primary rounded-br-xl text-white'
                style={{ backgroundColor: color_primary }}
              >
                Soumettre
              </button>
            </div>
          </form>
        </motion.div>
      </Backdrop>
    </>
  );
};

export default ParcoursCrm;
