import React, { useState, useEffect } from 'react';
import { Chart } from 'react-google-charts';

const CtmChart = ({ CARDS }) => {
  const [fontSize, setFontSize] = useState(16);

  useEffect(() => {
    const handleResize = () => {
      setFontSize(window.innerWidth <= 767 ? 8 : 16);
    };

    // Set initial font size
    handleResize();
    window.addEventListener('resize', handleResize);
    return () => {
      window.removeEventListener('resize', handleResize);
    };
  }, []);

  const options = {
    legend: 'none',
    pieSliceText: 'label',
    pieHole: 0.23,
    backgroundColor: 'transparent',
    tooltip: { trigger: 'none' },
    colors: CARDS.map((card) => card.color),
    pieSliceTextStyle: {
      color: '#ffffff',
      fontSize: fontSize,
      bold: true,
    },
    chartArea: {
      left: 0,
      top: 0,
      width: '100%',
      height: '100%',
    },
  };

  const transformData = () => {
    const data = [['Name', 'Deg']];
    CARDS.forEach((card) => {
      data.push([card.name, card.deg]);
    });
    return data;
  };

  const data = transformData();
  console.log('data', data);
  console.log('option', options);

  return (
    <Chart
      chartType='PieChart'
      data={data}
      options={options}
      className={'wheel_circle'}
    />
  );
};

export default CtmChart;
