import React, { useRef, useState, useEffect } from 'react';
import AnimatedPages from '../components/AnimatedPages';
import { useFormik } from 'formik';
import * as Yup from 'yup';
import SpinWheel from '../components/SpinWheel';
import { useParams } from 'react-router-dom';
import { WheelGameService } from './../_services/wheel-game.service';
import 'reactjs-popup/dist/index.css';
import Backdrop from '@material-ui/core/Backdrop';
import CircularProgress from '@material-ui/core/CircularProgress';
import { makeStyles } from '@material-ui/core/styles';
import diacritics from 'diacritics';
import moment from 'moment';

const goal = [
  {
    value: 'facebook',
    img: '../images/facebook.webp',
    step1_text: 'Soutenez-nous en likant notre page !',
    step2_text: 'Vous allez être redirigé vers notre page Facebook',
    step3_text: 'Likez notre page 👍',
  },
  {
    value: 'instagram',
    img: '../images/instagram.webp',
    step1_text: 'Soutenez-nous en suivant notre page !',
    step2_text: 'Vous allez être redirigé vers notre page Instagram',
    step3_text: 'Likez notre page 👍',
  },
  {
    value: 'google',
    img: '../images/google.webp',
    step1_text: 'Laissez-nous un avis et gagnez le gros lot',
    step2_text: 'Vous allez être redirigé vers notre page Google',
    step3_text: 'Laissez-nous un avis ⭐',
  },
  {
    value: 'custom',
    img: '../images/Custom lens.webp',
    step1_text: 'Soutenez-nous en visitant notre site !',
    step2_text: 'Vous allez être redirigé vers notre site, revenez sur cet onglet et profitez de nos cadeaux ! 🎁',
  },
];

const useStyles = makeStyles((theme) => ({
  backdrop: {
    zIndex: theme.zIndex.drawer + 1,
    color: '#fff',
  },
}));

const ContestGame = ({ data }) => {
  const { wheel_id } = useParams();
  const [loading, setLoading] = useState(true);
  const classes = useStyles();
  const [wheel, setWheel] = useState(null);

  const [Utkai, setUtkai] = useState('');
  const [UtkaiId, setUtkaiId] = useState(null);
  const [UtkaiType, setUtkaiType] = useState('');

  const [showPopup, setShowPopup] = useState(false);
  const [errorMessage, setErrorMessage] = useState(null);


  const decrypt = (string, shift, key) => {
    string = string.replace(key, '')
    let decryptedString = '';
    for (let i = 0; i < string.length; i++) {
      const char = string[i];
      const asciiCode = char.charCodeAt(0);
      const decryptedChar = asciiCode - shift;
      decryptedString += String.fromCharCode(decryptedChar);
    }
    return decryptedString;
  };
const fetchUpdatedWheelOptions = (wheelOptions) => {
  WheelGameService.calculateProbabilityAndRandomWin(wheelOptions)
    .then(response => {
      setUtkai(decrypt(response.utkaiN, 3, 'DG7PvOLvbVE75zADkg550m1risLS5Jx0'));
      setUtkaiId(decrypt(response.utkaiI, 5, 'qhXrdz4nkj7h2g7uiudqqnc0T7c8BLLL'));
      setUtkaiType(decrypt(response.utkaiT, 8, 'IaKAdSYnlV8jD1UOjKpnydecObSHIyYn'));
    })
    .catch(error => {
      console.error(error);
    });
};
const [wheeldatatocollect, setWheelDataToCollect] = useState([]);
  useEffect(() => {
    if (wheel_id) {
      setLoading(true);
      WheelGameService.getById(wheel_id)
        .then(res => {
          setWheel(res);
          setWheelDataToCollect(res.WheelDataToCollect)
          fetchUpdatedWheelOptions(res?.wheel?.wheel_options)
        })
        .catch(error => console.error('Error fetching data:', error))
        .finally(() => setLoading(false));
    }
  }, [wheel_id]);
  console.log('www',wheel)
  const {
    theme_screen1_logo,
    theme_screen1_title,
    theme_screen1_subtitle,
    theme_screen1_button_text,
    theme_screen1_background_image,
    theme_screen2_form_title,
    theme_screen2_form_subtitle,
    theme_screen2_form_button_text,
    wheel_user_requirement,
    wheel_user_url,
    theme_screen1_button_color,
    theme_screen1_button_text_color,
    theme_screen2_background_color,
    theme_screen2_wheel_color
  } = wheel?.wheel || {};

  
  // const [GridLoading, setGridLoading] = useState(false);
  const [socialmedia, setSocialMedia] = useState([]);

  const getSocialMedia = () => {
    // setGridLoading(true);
    WheelGameService.getAllSocialMedia(wheel_id)
      .then(res => {
        setSocialMedia(res.data);
      })
      .catch(err => {
        console.log(err);
      })
      .finally(() => {
        // setGridLoading(false);
      });
  };

  useEffect(() => {
    getSocialMedia();
  }, [wheel_id]);

  const targetRef = useRef(null);

  const [SpinTheWheel, setSpinTheWheel] = useState(false);

  const formik = useFormik({
    initialValues: {
      userName: '',
      phone: '',
      mail: '',
      age:'',
      gender:'',
      birth_date:'',
      address:''
    },
    validationSchema: Yup.object({
      phone: Yup.string()
      .matches(/^[2|5|9|4]\d{7}$/, 'Invalid phone number')
      .max(8, 'Phone number must be exactly 8 digits')
      .required('Required'),
      mail: Yup.string().email('Invalid email address'),
      age: Yup.number()
        .positive('Must be a positive number')
        .integer('Must be an integer'),
      gender: Yup.string().oneOf(['Male', 'Female'], 'Invalid gender'),
      birth_date: Yup.date(),
      address: Yup.string(),
    }),
    onSubmit: values => {
      setLoading(true);
      const payload = {
        wheel_id: wheel_id,
        wheel_option_id: UtkaiId,
        name: formik.values.userName,
        email: formik.values.mail,
        phone: formik.values.phone,
        age:formik.values.age,
        gender:formik.values.gender,
        birth_date:formik.values.birth_date,
        address:formik.values.address
      };
      WheelGameService.wheel(payload)
        .then((response) => {
          scrollToSection()
          setSpinTheWheel(true);
          setErrorMessage(null); 
        })
        .catch((error) => {
          console.log("error",error)
          if (error === 'already_played') {
            setErrorMessage('Vous avez déjà joué cette roue!');
          } else if (error.status === 'error' && error.action === 'wheel' && error.errors && error.errors.phone) {
            setErrorMessage('Le numéro de téléphone doit comporter 8 chiffres.');
          } else {
            // setErrorMessage('Une erreur s\'est produite. Veuillez réessayer plus tard.');
            setErrorMessage(error)
          }
        })
        .finally(() => {
          setLoading(false);
        });
    },
  });

  const scrollToSection = () => {
    targetRef.current.scrollIntoView({ behavior: 'smooth' });
  };

  const checkUserRequirement = () => {
    if (
      wheel_user_requirement === 'facebook' ||
      wheel_user_requirement === 'instagram' ||
      wheel_user_requirement === 'google' ||
      wheel_user_requirement === 'custom'
    ) {
      setShowPopup(true);
    } else if (wheel_user_requirement === 'clients_data') {
      setShowPopup(false);
    }
  };

  useEffect(() => {
    checkUserRequirement();
  }, [wheel_user_requirement]);

  useEffect(() => {
    // if (showPopup) {
    //   const timer = setTimeout(() => {
    //     setShowPopup(false);
    //   }, 10000);

    //   return () => clearTimeout(timer);
    // }
  }, [showPopup]);

  const selectedGoal = goal?.find(item => item.value === wheel_user_requirement);

  const redirectToSocialMedia = () => {
    if (wheel_user_requirement === 'custom') {
      // navigate(wheel_user_url);
      // window.location.href = wheel_user_url;
      window.open(wheel_user_url, '_blank');
    } else {
      const matchingSocialMedia = socialmedia.find(item => item.name === wheel_user_requirement);
      if (matchingSocialMedia) {
        // navigate(matchingSocialMedia.url);
        window.open(matchingSocialMedia.url, '_blank');
        // window.location.href = matchingSocialMedia.url;

      }
    }
    setShowPopup(false);
  };
  const removeApostrophes = (inputString) => {
    if (!inputString || typeof inputString !== 'string') {
      return inputString;
    }
    let stringWithoutApostrophes = inputString.replace(/'/g, '');
    stringWithoutApostrophes = diacritics.remove(stringWithoutApostrophes);
    return stringWithoutApostrophes;
  };
  const [disableButton, setDisableButton] = useState(false);
  const [remainingTime, setRemainingTime] = useState('');

  useEffect(() => {
    const currentDate = moment(); // Utilisation de Moment.js pour obtenir la date actuelle
    const scheduleDate = moment(wheel?.wheel?.schedule_date);
    const expiryDate = moment(wheel?.wheel?.expiry_date);

    if (currentDate > expiryDate) {
      setRemainingTime("Ce jeu de roues a expiré");
      setDisableButton(true);
    } else if (currentDate < scheduleDate) {
      const diffInDays = scheduleDate.diff(currentDate, 'days');
      const diffInHours = scheduleDate.diff(currentDate, 'hours') % 24;
      if (diffInDays === 0) {
        setRemainingTime(`Ce jeu de roue commence dans ${diffInHours} heures`);
      } else {
          setRemainingTime(`Ce jeu de roue commence dans ${diffInDays} jours et ${diffInHours} heures`);
      }
      setDisableButton(true);
    } else {
      const diffInDays = expiryDate.diff(currentDate, 'days');
      const diffInHours = expiryDate.diff(currentDate, 'hours') % 24;
      if (diffInDays === 0) {
        setRemainingTime(`Il vous reste ${diffInHours} heures pour jouer`);
      } else {
          setRemainingTime(`Il vous reste ${diffInDays} jours et ${diffInHours} heures pour jouer`);
      }
      setDisableButton(false);
    }
}, [wheel?.wheel?.schedule_date, wheel?.wheel?.expiry_date]);

const redirectToFidelfy = () => {
  // Rediriger l'utilisateur vers fidelfy.com en utilisant window.location.href
  window.location.href = 'https://fidelfy.com/';
};


  return (
    <AnimatedPages theme_screen1_background_image={theme_screen1_background_image}>
                {
        loading && (
          <Backdrop className={classes.backdrop} open>
            <CircularProgress color='inherit' />
          </Backdrop>
        )
      }
       <div
        className="bg-cover bg-center relative flex flex-col items-center text-center justify-center  lg:py-36 px-6 md:p-24"
        style={{paddingBottom: "50px",paddingTop:"1px"}}
      >
       
        <div className="fixed top-0 left-0 right-0 w-full h-full bg-black bg-opacity-60 z-1"></div>
        <div className="z-10 w-full space-y-5 md:max-w-5xl">
          <img src={theme_screen1_logo} style={{ height: '100px', margin: '0 auto' }} alt="" />
          <h1 className="text-3xl font-bold text-white md:text-5xl">{theme_screen1_title}</h1>
          <p className="mt-3 text-2xl text-white">{theme_screen1_subtitle}</p>
          {
            theme_screen1_button_text?
              <button
                className="bg-primary text-buttonText uppercase px-8 py-3 rounded-md text-xl  w-2/3 mt-4"
                onClick={scrollToSection}
                style={{
                      backgroundColor:theme_screen1_button_color,
                      color:theme_screen1_button_text_color
                }}  
              >
                {theme_screen1_button_text}
              </button>
            :
              ''  
          }
        </div>
      </div>
      <div 
          ref={targetRef}      

        >
        <div className="flex flex-col items-center gap-12 px-16 xl:flex-row xl:justify-between">
 
          {
              wheel?.wheel?.wheel_options.length > 0 && Utkai && (
                <div className='z-10 mb-10'>
                  <form onSubmit={formik.handleSubmit} className="space-y-4" lang='fr'>
                    <div>
                      <h1 className="mb-2 text-3xl font-bold text-center md:text-left text-white">
                        {theme_screen2_form_title}
                      </h1>
                      <p className="mb-8 text-xl text-center md:text-left text-white">{theme_screen2_form_subtitle}👇</p>
                      <input
                        placeholder="Numéro de téléphone"
                        id="phone"
                        name="phone"
                        type="tel"
                        onChange={formik.handleChange}
                        onBlur={formik.handleBlur}
                        value={formik.values.phone}
                        className={`w-full px-2 py-4 border outline-none rounded-xl ${
                          formik.touched.phone && formik.errors.phone
                            ? 'border-red-700'
                            : 'border-gray-500'
                        }`}
                        required
                        maxLength="8"
                      />
                        {formik.touched.phone && formik.errors.phone && (
                            <div className="text-red-700">Phone number must be a valid 8-digit number</div>
                        )}
                                   
                     </div>
                    {wheeldatatocollect.map((field, index) => {
                      switch (field.data_name) {
                        case 'name':
                          return (
                          <div>
                              <input
                              placeholder="Prénom"
                              id="userName"
                              name="userName"
                              type="text"
                              onChange={formik.handleChange}
                              onBlur={formik.handleBlur}
                              value={formik.values.userName}
                              className={`w-full px-2 py-4 border outline-none rounded-xl ${
                                formik.touched.userName && formik.errors.userName
                                  ? 'border-red-700'
                                  : 'border-gray-500'
                              }`}
                              required
                              
                            />
                              {formik.touched.userName && formik.errors.userName && (
                                 <div className="text-red-700">{formik.errors.userName}</div>
                              )}
                          </div>
                        );
                        case 'email':
                         return (
                          <div>
                            <input
                              id="mail"
                              name="mail"
                              type="email"
                              placeholder="Email"
                              onChange={formik.handleChange}
                              onBlur={formik.handleBlur}
                              value={formik.values.mail}
                              className={`w-full px-2 py-4 border outline-none rounded-xl ${
                                formik.touched.mail && formik.errors.mail
                                  ? 'border-red-700'
                                  : 'border-gray-500'
                              }`}
                              required
                            />
                            {formik.touched.mail && formik.errors.mail && (
                               <div className="text-red-700">{formik.errors.mail}</div>
                             )}
                          </div>
                        );
                        case 'age':
                          return (
                          <div>
                            <input
                              id="age"
                              name="age"
                              type="number"
                              placeholder="age"
                              onChange={formik.handleChange}
                              onBlur={formik.handleBlur}
                              value={formik.values.age}
                              className={`w-full px-2 py-4 border outline-none rounded-xl ${
                                formik.touched.age && formik.errors.age
                                  ? 'border-red-700'
                                  : 'border-gray-500'
                              }`}
                              required
                            />
                          </div>
                        );
                        case 'gender':
                          return (
                            <div>
                              <select
                                id="gender"
                                name="gender"
                                onChange={formik.handleChange}
                                onBlur={formik.handleBlur}
                                value={formik.values.gender}
                                className={`w-full px-2 py-4 border outline-none rounded-xl ${
                                  formik.touched.gender && formik.errors.gender
                                    ? 'border-red-700'
                                    : 'border-gray-500'
                                }`}
                                required
                              >
                                <option value="">Choisir votre genre</option>
                                <option value="Male">Homme</option>
                                <option value="Female">Femme</option>
                              </select>
                              {formik.touched.gender && formik.errors.gender && (
                                <div className="text-red-700">{formik.errors.gender}</div>
                              )}
                          </div>
                        );
                        case 'address':
                          return (
                          <div>
                            <input
                              id="address"
                              name="address"
                              type="text"
                              placeholder="Adresse"
                              onChange={formik.handleChange}
                              onBlur={formik.handleBlur}
                              value={formik.values.address}
                              className={`w-full px-2 py-4 border outline-none rounded-xl ${
                                formik.touched.address && formik.errors.address
                                  ? 'border-red-700'
                                  : 'border-gray-500'
                              }`}
                              required
                            />
                          </div>
                        );
                        case 'birth_date':
                          return (
                            <div>
                              <label for="birth_date" style={{"color": "#fff"}}>
                                Date de naissance
                              </label>
                              <input
                                id="birth_date"
                                name="birth_date"
                                type="date"
                                placeholder="birth_date"
                                onChange={formik.handleChange}
                                onBlur={formik.handleBlur}
                                value={formik.values.birth_date}
                                className={`w-full px-2 py-4 border outline-none rounded-xl ${
                                  formik.touched.birth_date && formik.errors.birth_date
                                    ? 'border-red-700'
                                    : 'border-gray-500'
                                }`}
                                required
                                style={{
                                  fontSize: '16px', 
                                  padding: '12px', 
                                }}
                              />
                          </div>
                        );
                          default:
                            return ''; 
                        }
                    })}
                    {errorMessage && (
                      <div className="error-popup">
                        <div className="error-message">{errorMessage}</div>
                      </div>
                    )}
                    <div style={{cursor: 'pointer'}}>
                      {/* J'accepte les politiques de confidentialité et les conditions générales d'utilisation */}
                      <input
                        type="checkbox"
                        id="policy"
                        name="policy"
                        className="mr-2"
                        required
                      />
                      <label for="policy" style={{"color": "#fff"}}>
                        J'accepte les politiques de confidentialité et les conditions générales d'utilisation
                      </label>
                    </div>
                    <div className="flex justify-center">
                      {
                        theme_screen2_form_button_text && disableButton ? (
                          <button
                            type="button"
                            className="bg-gray-400 text-buttonText uppercase px-8 py-3 rounded-full text-xl w-2/3 cursor-not-allowed"
                            style={{
                              backgroundColor: "red",
                              color: 'white'
                            }}
                            disabled
                          >
                            {remainingTime}
                          </button>
                        ) : (
                          <button
                            type="submit"
                            className="bg-primary text-buttonText uppercase px-8 py-3 rounded-full text-xl w-2/3"
                            style={{
                              backgroundColor: theme_screen2_background_color,
                              color: theme_screen2_wheel_color
                            }}
                          >
                            {theme_screen2_form_button_text}
                          </button>
                        )
                      }
                    </div>

                  </form>
                </div>
                
             )
            }
            <div className="py-10" style={{position: 'relative'}}>
            {
              wheel?.wheel?.wheel_options.length > 0 && Utkai && (
                <SpinWheel 
                  errorMessage={errorMessage}
                  wheelOptions={wheel?.wheel?.wheel_options || []} 
                  utkai={removeApostrophes(Utkai)} 
                  utkaiType={UtkaiType} 
                  spinTheWheel={SpinTheWheel} 
                  handleSubmit={formik.handleSubmit}
                  logo={theme_screen1_logo}
                  theme_screen1_button_color={theme_screen1_button_color}
                />
              )
            }
          </div>
       
        </div>
  
      </div>
      {showPopup && (
        <div className="modalBackground">
          <div className="modalContainer">
            <div style={{ width: '100%', textAlign: 'center', marginBottom: '20px',marginLeft:'150px' }}>
              <img src={selectedGoal?.img} alt="goal Image" style={{ height: '70px' }}  />
            </div>
            <h6 variant="h6" style={{ fontWeight: 'bold', color: 'black', textAlign: 'center' }}>
              {wheel_user_requirement}
            </h6>
            <div style={{ display: 'flex', alignItems: 'left', my: '20px' }}>
              <h4 variant="h4" style={{ fontWeight: 'bold', marginRight: '10px', color: 'black' }}>
                1
              </h4>
              <p variant="body1" style={{ textAlign: 'left', color: 'black' }}>
                {selectedGoal?.step1_text}
              </p>
            </div>
            <div style={{ display: 'flex', alignItems: 'left', my: '20px' }}>
              <h4 variant="h4" style={{ fontWeight: 'bold', marginRight: '10px', color: 'black' }}>
                2
              </h4>
              <p variant="body1" style={{ textAlign: 'left', color: 'black' }}>
                {selectedGoal?.step2_text}
              </p>
            </div>
            <div style={{ display: 'flex', alignItems: 'left', my: '20px' }}>
              <h4 variant="h4" style={{ fontWeight: 'bold', marginRight: '10px', color: 'black' }}>
                3
              </h4>
              <p variant="body1" style={{ textAlign: 'left', color: 'black' }}>
                {selectedGoal?.step3_text}
              </p>
            </div>
            <div className="footer">
              <button
                onClick={() => {
                  redirectToSocialMedia();
                }}
                id="cancelBtn"
                style={{ variant: 'contained', color: 'primary' }}
              >
                Accéder au site
              </button>
            </div>
          </div>
        </div>
      )}
    
         <div  style={{position: 'relative'}}>
              <section className="text-center py-4 text-sm text-white">
                  Proposé par
                  <span
                    onClick={redirectToFidelfy}
                    className="font-bold cursor-pointer hover:underline"
                    style={{marginLeft:"5px"}}
                  >
                    Fidelfy.com
                  </span>
                </section>
          </div>


    </AnimatedPages>
    
  );
};

export default ContestGame;
