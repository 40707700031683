import { authHeader, guestHeader, ApiConfigs } from '../_helpers';

export const WheelGameService = {
    getAll,
    getAllSocialMedia,
    getById,
    calculateProbabilityAndRandomWin,
    collectPoints,
    wheel
};

async function getAll() {
    const requestOptions = {
      method: 'GET',
      headers: { ...authHeader(), 'Content-Type': 'application/json' },
    };
  
    return await fetch(
      `${ApiConfigs.base_url}${ApiConfigs.wheelgame.getAll}`,
      requestOptions
    ).then(handleResponse);
  }
async function getAllSocialMedia(wheelId) {
    const requestOptions = {
        method: 'GET',
        headers: { ...authHeader(), 'Content-Type': 'application/json' },
    };
    return await fetch(
        `${ApiConfigs.base_url}${ApiConfigs.wheelgame.getAllSocialMedia.replace('{wheelId}', wheelId)}`,
        requestOptions
    ).then(handleResponse);
}
  async function getById(id) {
    const requestOptions = {
      method: 'GET',
      headers: { ...authHeader(), 'Content-Type': 'application/json' }
    }
    return await fetch(`${ApiConfigs.base_url}${ApiConfigs.wheelgame.getById.replace('{id}', id)}`, requestOptions).then(
      handleResponse
    )
  }
  async function calculateProbabilityAndRandomWin(wheelAwardOptions) {
    const requestOptions = {
      method: 'POST',
      headers: { ...authHeader(), 'Content-Type': 'application/json' },
      body: JSON.stringify({ wheelAwardOptions }),
    };
  
    return await fetch(
      `${ApiConfigs.base_url}${ApiConfigs.wheelgame.Utkai}`,
      requestOptions
    ).then(handleResponse);
  }
  async function collectPoints() {
    const requestOptions = {
      method: 'GET',
      headers: { ...authHeader(), 'Content-Type': 'application/json' },
    };
  
    return await fetch(
      `${ApiConfigs.base_url}${ApiConfigs.wheelgame.collectPoints}`,
      requestOptions
    ).then(handleResponse);
  }

  async function wheel(requestData) {
    const requestOptions = {
      method: 'POST',
      headers: { ...authHeader(), 'Content-Type': 'application/json' },
      body: JSON.stringify(requestData),
    };
  
    return await fetch(
      `${ApiConfigs.base_url}${ApiConfigs.wheelgame.loginWheel}`,
      requestOptions
    ).then(handleResponse);
  }
function handleResponse(response) {
    return response.text().then(text => {
        const data = text && JSON.parse(text);
        if (!response.ok) {
            if (response.status === 401) {
                window.location.href = '/login'
            }

            const error = (data && data.message) || response.statusText;
            return Promise.reject(error);
        }

        return data;
    });
}