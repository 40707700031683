import { authHeader, guestHeader, ApiConfigs } from '../_helpers';

export const RewardsService = {
    getAll,
    Create
};

async function getAll() {
    const requestOptions = {
      method: 'GET',
      headers: { ...authHeader(), 'Content-Type': 'application/json' },
    };
  
    return await fetch(
      `${ApiConfigs.base_url}${ApiConfigs.rewards.getAll}`,
      requestOptions
    ).then(handleResponse);
  }

async function Create(tablette) {
  const requestOptions = {
    method: 'POST',
    headers: { ...authHeader(), 'Content-Type': 'application/json'  },
    body: JSON.stringify(tablette)
  }

  return await fetch(`${ApiConfigs.base_url}${ApiConfigs.rewards.store}`, requestOptions).then(handleResponse)
}
  


function handleResponse(response) {
    return response.text().then(text => {
        const data = text && JSON.parse(text);
        if (!response.ok) {
            if (response.status === 401) {
                window.location.href = '/login'
            }

            const error = (data && data.message) || response.statusText;
            return Promise.reject(error);
        }

        return data;
    });
}