import React, { useEffect, useState } from 'react';
import AnimatedPages from '../components/AnimatedPages';
import { useNavigate } from 'react-router';
import { AuthService } from './../_services/auth.service';

import Backdrop from '@material-ui/core/Backdrop';
import CircularProgress from '@material-ui/core/CircularProgress';
import { makeStyles } from '@material-ui/core/styles';

const useStyles = makeStyles((theme) => ({
  backdrop: {
    zIndex: theme.zIndex.drawer + 1,
    color: '#fff',
  },
}));

const InputNumbers = ({ data }) => {
  const classes = useStyles();

  const navigate = useNavigate();
  const [display, setDisplay] = useState('');
  const [loading, setLoading] = useState(false);
  const [acceptSMS, setAcceptSMS] = useState(true);

  const PHONE_FIRST_NUMBER_RESTRICTION = JSON.parse(process.env.REACT_APP_PHONE_FIRST_NUMBER_RESTRICTION) ?? [];

  const [ErrorMessage, setErrorMessage] = useState('');
  const handleNumberClick = (number) => {
    let updatedDisplay = display;
    if( updatedDisplay.length == 0 && !PHONE_FIRST_NUMBER_RESTRICTION.includes(number) ){
      setErrorMessage(
        'Le numéro de téléphone doit commencer par '+
        PHONE_FIRST_NUMBER_RESTRICTION.slice(0, -1).join(', ') +
        ' ou ' +
        PHONE_FIRST_NUMBER_RESTRICTION.slice(-1)
      );
      return false;
    }else{
      setErrorMessage('')
    }
    
    if (updatedDisplay.length === 2 || updatedDisplay.length === 6) {
      updatedDisplay += ' ';
    }

    if (updatedDisplay.replace(/\s/g, '').length === 8) {
      return;
    }

    updatedDisplay += number;
    setDisplay(updatedDisplay);

    if (updatedDisplay.length === 10) {
      setLoading(true);
      let data = {
        phone: updatedDisplay.replace(/\s/g, ''),
        accept_sms: acceptSMS
      }
      AuthService.clientlogin(data)
        .then((response) => {
          localStorage.setItem('tablette_client', JSON.stringify(response.client.client));
          localStorage.setItem('client_new_points', JSON.stringify(response.client.client_new_points));
          localStorage.setItem('parcours_crm', JSON.stringify(response.client.parcour_crm));
          
          const { status } = response;
          if (status === 'error' && response.message === 'The provided credentials are incorrect.') {
            alert('Unknown error occured! Please try again later.');
          } else {
              navigate('/points');
          }
        })
        .catch((error) => {
          console.error(error);
        })
        .finally(() => {
          setLoading(false);
        });
    }
  };

  const handleCheckboxChange = (event) => {
    setAcceptSMS(event.target.checked);
  };

  const handleDelete = () => {
    let updatedDisplay = display;

    if (updatedDisplay.slice(-1) === ' ') {
      updatedDisplay = updatedDisplay.slice(0, -2);
    } else {
      updatedDisplay = updatedDisplay.slice(0, -1);
    }

    setDisplay(updatedDisplay);
  };

  const [kioskUser, setKioskUser] = useState(null);

  useEffect(() => {
    const storedUser = localStorage.getItem('kiosk_tablette');
    if (storedUser) {
      setKioskUser(JSON.parse(storedUser));
    }
  }, []);

  const { screen_phone_title, screen_phone_subtitle,color_primary } = kioskUser?.tablette_settings || {};
  const { business_name ,sender_id } = kioskUser?.partner || "ce partenaire";

  return (
    <AnimatedPages>
      <section className='grid items-center h-screen grid-cols-12'>
        <div className='flex flex-col justify-center h-screen col-start-1 col-end-7 space-y-6 text-white  pl-14' style={{backgroundColor:color_primary}}>
          <h1 className='text-7xl'>{screen_phone_title}</h1>
          <p className='text-2xl'>{screen_phone_subtitle}</p>
        </div>
        <div className='flex flex-col justify-center h-screen col-start-7 col-end-13 p-12 pr-8'>
          <div className='relative'>
            <input
              type='text'
              className='w-full px-4 py-2 mb-6 text-4xl font-bold text-center rounded-md'
              value={display}
              readOnly
            />
            <button
              className='absolute top-0 right-0 px-4 py-2 mr-2 rotate-180 translate-y-2 border border-gray-300 rounded-md text-buttonText bg-primary after:absolute after:content-[""] after:w-0 after:h-0 after:border-t-transparent after:border-b-transparent after:border-b-[16px] after:top-1/2 after:left-[45px] after:translate-y-[-50%] after:rotate-180 after:border-r-primary after:border-r-[16px]'
              onClick={handleDelete}
              disabled={display.length === 0}
            >
              <svg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 24 24' fill='currentColor' className='w-4 h-4'>
                <path
                  fillRule='evenodd'
                  d='M12 2C6.48 2 2 6.48 2 12c0 5.52 4.48 10 10 10s10-4.48 10-10c0-5.52-4.48-10-10-10zm3.707 13.293a.999.999 0 1 1-1.414 1.414L12 13.414l-2.293 2.293a.999.999 0 1 1-1.414-1.414L10.586 12l-2.293-2.293a.999.999 0 1 1 1.414-1.414L12 10.586l2.293-2.293a.999.999 0 1 1 1.414 1.414L13.414 12l2.293 2.293z'
                />
              </svg>
            </button>
          </div>

          <div className='grid grid-cols-3 gap-8 mt-2'>
            {[1, 2, 3, 4, 5, 6, 7, 8, 9, 0].map((number) => (
              <button
                key={number}
                className={`px-4 py-2 hover:bg-gray-200 rounded-md mr-2 text-3xl ${
                  number === 0 ? 'col-span-full' : ''
                }`}
                onClick={() => handleNumberClick(number)}
              >
                {number}
              </button>
            ))}
          </div>
          <div className='flex justify-center items-center mt-4'>
            <label className='text-sm' style={{color: 'red'}}>
              {ErrorMessage}
            </label>
          </div>
          <div className='flex items-center mt-4'>
            <input
              type='checkbox'
              id='acceptSmsCheckbox'
              checked={acceptSMS}
              onChange={handleCheckboxChange}
              className='mr-2'
            />
            <label htmlFor='acceptSmsCheckbox' className='text-sm'>
              J'accepte de recevoir des SMS de "{sender_id}"
            </label>
          </div>
        </div>
      </section>
      {loading && (
        <Backdrop className={classes.backdrop} open>
          <CircularProgress color='inherit' />
        </Backdrop>
      )}
    </AnimatedPages>
  );
};

export default InputNumbers;
