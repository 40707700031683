import { authHeader, ApiConfigs } from '../_helpers';

export const ParcoursCrmService = {
    setData,
};

async function setData(data) {
  const requestOptions = {
    method: 'POST',
    headers: { ...authHeader(), 'Content-Type': 'application/json' },
    body: JSON.stringify(data)
  };

  return await fetch(
    `${ApiConfigs.base_url}${ApiConfigs.parcoursCrm.setData}`,
    requestOptions
  ).then(handleResponse);
}

function handleResponse(response) {
  return response.text().then(text => {
      const data = text && JSON.parse(text);
      if (!response.ok) {
          if (response.status === 401) {
              window.location.href = '/login'
          }

          const error = (data && data.message) || response.statusText;
          return Promise.reject(error);
      }

      return data;
  });
}