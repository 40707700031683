import React, { useEffect, useState } from 'react';
import SliderImages from '../components/SliderImages';
import { RewardsService } from './../_services/rewards.service';
import { AuthService } from './../_services/auth.service';
import { useNavigate } from 'react-router-dom';
import 'react-loading-skeleton/dist/skeleton.css';
import CircularProgress from '@material-ui/core/CircularProgress';
import Backdrop from '@material-ui/core/Backdrop';
import { makeStyles } from '@material-ui/core/styles';
import ParcoursCrm from '../components/ParcoursCrm';
import Skeleton, { SkeletonTheme } from 'react-loading-skeleton';
import 'react-loading-skeleton/dist/skeleton.css';

const useStyles = makeStyles((theme) => ({
  backdrop: {
    zIndex: theme.zIndex.drawer + 1,
    color: '#fff',
  }
}));

const Welcome = ({ data }) => {
  const [kioskUser, setKioskUser] = useState(null);
  const navigate = useNavigate();
  const classes = useStyles();

  const TIMEOUT_DURATION = 120000;

  const [ParcoursCrmData, setParcoursCrmData] = useState(null);

  useEffect(() => {
    const storedUser = localStorage.getItem('kiosk_tablette');
    if (storedUser) {
      setKioskUser(JSON.parse(storedUser));
    }

    const parcours_crm = localStorage.getItem('parcours_crm');
    if (parcours_crm) {
      setParcoursCrmData(JSON.parse(parcours_crm));
    }
  }, []);

  const [kioskphone, setKioskPhone] = useState(null);

  useEffect(() => {
    const storedPhone = localStorage.getItem('tablette_client');
    if (storedPhone) {
      setKioskPhone(JSON.parse(storedPhone));
    }else{
      navigate('/');
    }
  }, []);

  // kioskUser
  const { screen_awards_header_title, screen_awards_header_subtitle, screen_awards_points_title, logo, color_primary, color_secondary } =
    kioskUser?.tablette_settings || {};

  // kioskphone
  const { id, points, name, client_type_id, visit_id } = kioskphone || {};
  const partner_client_id = id;

  const displayPoints = points ?? 0;

  const [GridLoading, setGridLoading] = useState(true);
  const [rewards, setRewards] = useState([]);

  const getRewards = () => {
    setGridLoading(true);
    RewardsService.getAll()
      .then(res => {
        setRewards(res.rewards);
      })
      .catch(err => {
        console.log(err);
      })
      .finally(() => {
        setGridLoading(false);
      });
  };

  useEffect(() => {
    getRewards();
  }, []);
  const [loading, setLoading] = useState(false);

  const handleLogout = () => {
    setLoading(true);

    AuthService.logout(visit_id)
      .then(() => {
        localStorage.removeItem('tablette_client');
        localStorage.removeItem('kiosk_purchased_item');
        localStorage.removeItem('client_new_points');
        localStorage.removeItem('parcours_crm');
        navigate('/');
      })
      .catch(error => {
        console.log(error);
      })
      .finally(() => {
        setLoading(false);
      });
  };

  let logoutTimeout;

  const resetLogoutTimeout = () => {
    if (logoutTimeout) {
      clearTimeout(logoutTimeout);
    }

    logoutTimeout = setTimeout(() => {
      handleLogout();
    }, TIMEOUT_DURATION);
  };

  const handleActivity = () => {
    resetLogoutTimeout();
  };

  useEffect(() => {
    const activityEvents = ['mousemove', 'keydown', 'mousedown', 'touchstart'];

    const handleActivityEvent = () => {
      handleActivity();
    };

    activityEvents.forEach(event => {
      document.addEventListener(event, handleActivityEvent);
    });

    resetLogoutTimeout();

    return () => {
      if (logoutTimeout) {
        clearTimeout(logoutTimeout);
      }

      activityEvents.forEach(event => {
        document.removeEventListener(event, handleActivityEvent);
      });
    };
  }, []);

  return (
    <>
      {loading && (
        <Backdrop className={classes.backdrop} open>
          <CircularProgress color='inherit' />
        </Backdrop>
      )}

      <section className="h-screen space-y-6 overflow-hidden">
        <div className="flex justify-between p-4 text-text-main">
          <img src={logo} alt='Logo' className='w-100 h-20 mr-2' />
          <div className='text-center'>
            <h1 className="max-w-6xl font-black sm:text-2xl lg:text-3xl ">
              {screen_awards_header_title} {name ?? ''}!
            </h1>
            <p className="max-w-lg text-2xl font-light">{screen_awards_header_subtitle}</p>
          </div>

          <button
            className="px-8 py-3 text-sm uppercase border-2 rounded-full md:text-2xl border-primary text-primary"
            onClick={handleLogout}
          >
            Se Déconnecter
          </button>
        </div>
        <div className="h-full bg-primary" style={{ backgroundColor: color_primary, marginTop: '0px' }}>
          <div className="flex flex-col items-center justify-center p-6 text-secondary">
            <h2 className="font-light uppercase sm:text-2xl lg:text-3xl">{screen_awards_points_title}</h2>
            <h3 className="font-extrabold sm:text-5xl lg:text-6xl">{displayPoints} Points</h3>
          </div>
          {
            !GridLoading ?
              client_type_id === null || rewards.some(reward => reward.client_type_id === null) ? (
                <SliderImages data={rewards} displayPoints={displayPoints} type={client_type_id} color_primary={color_primary} color_secondary={color_secondary} />
              ) : (
                ''
              )
            :
              <>
                <div>
                  <SkeletonTheme baseColor="#ccc" highlightColor="#eee">
                    <div className='flex items-center justify-left'>
                      {
                        new Array(10).fill(0).map((_, index) => (
                          <div key={`HomeSkeleton${index}`} style={{margin: '0 13px'}}>
                            <Skeleton count={1} width={'295px'} height={'470px'} borderRadius={'5px'} />
                          </div>
                        ))
                      }
                    </div>
                  </SkeletonTheme>
                </div>
                {/* <SliderImages parentLoading={GridLoading} color_primary={color_primary} color_secondary={color_secondary} /> */}
              </>
          }
        </div>
      </section>

      {
        !GridLoading && ParcoursCrmData?
          <ParcoursCrm 
            partner_client_id={partner_client_id}
            parcoursCrmData={ParcoursCrmData} 
            handleClose={() => setParcoursCrmData(null)}
          />
        :
          ''
      }
    </>
  );
};

export default Welcome;
