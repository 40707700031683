function server() {
  return process.env.REACT_APP_API_URL || 'http://localhost:8000/api'
}

export const ApiConfigs = {
  base_url: server() + '/kiosk',
  root_url: server(),
  /* -------------------------------- */
  auth: {
    login: '/login',
    clientlogin: '/clientlogin',
    logout: '/logout'
  },
  rewards: {
    getAll: '/rewards',
    store: '/rewards',
  },
  wheelgame: {
    getAll: '/wheel',
    collectPoints: '/wheel/collectPoints/points',
    getAllSocialMedia: '/wheel/SocialMedia/{wheelId}',
    getById: '/wheel/{id}',
    Utkai:'/wheel/Utkai',
    loginWheel:'/wheel/Wheel'
  },
  parcoursCrm: {
    setData: '/parcoursCrm/setData',
  }
}
