import React,{useState,useEffect} from 'react';
import { motion } from 'framer-motion';
import Backdrop from './Backdrop';

const dropIn = {
  hidden: {
    y: '100vh',
  },
  visible: {
    y: '0',

    transition: {
      duration: 0.8,
      type: 'spring',
    },
  },
  exit: {
    y: '100vh',
  },
};

const Dialog = ({ handleCreate, handleClose, text, displayPoints, pointsRequired }) => {
  const [kioskUser, setKioskUser] = useState(null);
  useEffect(() => {
    const storedUser = localStorage.getItem('kiosk_tablette');
    if (storedUser) {
      setKioskUser(JSON.parse(storedUser));
    }
  }, []);

  const {color_primary,color_secondary } =
  kioskUser?.tablette_settings || {};
  const handleSubmit = async () => {
    handleCreate();
  };


  let dialogContent = null;

  if (displayPoints >= pointsRequired) {
    dialogContent = (
      <div className='w-[clamp(100%,700px,90%)] h-[min(100%,340px)] mx-auto rounded-xl flex flex-col items-center bg-white'>
        <img src={'https://img.freepik.com/premium-vector/green-check-mark-3d-icon-vector-illustration_567896-126.jpg?w=2000'} style={{height: '150px'}} />
        <h1 className='mt-2 mb-2 text-2xl font-bold md:text-3xl'>Yeah !</h1>
        <p className='max-w-lg text-lg text-center'>{text}</p>
        <div className='flex items-end w-full h-full'>
          <button
            onClick={handleClose}
            className='w-full py-4 bg-primary opacity-80 rounded-bl-xl text-white'
            style={{backgroundColor:color_secondary}}
          >
            Non
          </button>
          <button
            onClick={handleSubmit}
            className='w-full py-4 bg-primary rounded-br-xl text-white'
            style={{backgroundColor:color_primary}}
          >
            Oui
          </button>
        </div>
      </div>
    );
  } else if (displayPoints < pointsRequired) {
    dialogContent = (
      <div className='w-[clamp(100%,700px,90%)] h-[min(100%,340px)] mx-auto rounded-xl flex flex-col items-center bg-white'>
        <img src={'https://cdn3d.iconscout.com/3d/premium/thumb/oops-4085884-3379593.png'} style={{height: '150px'}} />
        <p className='max-w-lg text-lg text-center'>
          You don't have enough points to buy this award!
        </p>
        <h1 className='mt-2 mb-2 text-2xl font-bold md:text-3xl'>Malheureusement!</h1>
        <div className='flex items-end w-full h-full'>
          <button
            onClick={handleClose}
            className='w-full py-4 bg-primary opacity-80 rounded-bl-xl text-white'
            style={{backgroundColor:color_secondary}}
          >
            Fermer
          </button>
        </div>
      </div>
    );
  }

  return (
    <Backdrop onClick={handleClose}>
      <motion.div
        onClick={(e) => e.stopPropagation()}
        className='w-[clamp(50%,700px,90%)] h-[min(50%,340px)] mx-auto rounded-xl flex flex-col items-center bg-white'
        variants={dropIn}
        initial={'hidden'}
        animate={'visible'}
        exit={'exit'}
      >
        {dialogContent}
      </motion.div>
    </Backdrop>
  );
};

export default Dialog;
