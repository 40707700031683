import React, { useEffect, useRef, useState } from 'react';
import { motion } from 'framer-motion';
import Dialog from './Dialog';
import Skeleton, { SkeletonTheme } from 'react-loading-skeleton';
import 'react-loading-skeleton/dist/skeleton.css';
import { RewardsService } from './../_services/rewards.service';
import { useNavigate } from 'react-router';
import CircularProgress from '@material-ui/core/CircularProgress';
import { makeStyles } from '@material-ui/core/styles';
import Backdrop from '@material-ui/core/Backdrop';

const useStyles = makeStyles((theme) => ({
  backdrop: {
    zIndex: theme.zIndex.drawer + 1,
    color: '#fff',
  },
}));

const SliderImages = ({type, data, displayPoints, parentLoading = false ,color_primary, color_secondary}) => {

  const classes = useStyles();


  const [loading, setLoading] = useState(false);
  const [dialogIsOpen, setDialogIsOpen] = useState(false);
  const [width, setWidth] = useState(0);
  const [pointsValue, setPointsValue] = useState([]);
  const carousel = useRef();
  const navigate = useNavigate();

  const handleClick = (value, title, points_required, id) => {
    setDialogIsOpen(true);
    setPointsValue([value, title, points_required, id]);
  };

  useEffect(() => {
    setWidth(carousel.current.scrollWidth - carousel.current.offsetWidth);
  }, []);

  const [kioskphone, setKioskPhone] = useState(null);

  useEffect(() => {
    const storedPhone = localStorage.getItem('tablette_client');
    if (storedPhone) {
      setKioskPhone(JSON.parse(storedPhone));
    }
  }, []);

  const handleCreate = () => {
    setLoading(true);
    
    let data = {
      reward_id: pointsValue[3] ?? null,
      client_id: kioskphone.id ?? null,
    }

    RewardsService.Create(data)
      .then((response) => {
        localStorage.setItem('tablette_client', JSON.stringify(response.client));
        localStorage.setItem('kiosk_purchased_item', JSON.stringify(response.client_rewards_purchased));
        navigate('/receiveGift');
      })
      .catch((error) => {
        console.error(error);
      })
      .finally(() => {
        setLoading(false);
      });
  };
  
  return (
    <SkeletonTheme baseColor="#202020" highlightColor="#444">
      <motion.div className="px-2 overflow-hidden cursor-grab" ref={carousel} whileTap={{ cursor: 'grabbing' }}>
        <motion.div className="flex space-x-7" drag="x" dragConstraints={{ right: 0, left: -width }}>
        {
          !parentLoading ?
            data.map(({ image, alt, id, name, points_required, client_type_id }) => {
              const isVIP = client_type_id == 2;
              const iAmVip = type == 2;

              return (
              <motion.div
                className="bg-white border-4 border-white rounded-lg relative"
                key={id}
                style={{ opacity: isVIP && !iAmVip ? 0.6 : 1 }}
              >
                {isVIP ? (
                      <div className="absolute top-0 right-0">
                        <img
                          src="/images/vip.png"
                          alt="VIP"
                          style={{ width: '60px', height: '60px', position:'relative', background: color_primary, borderRadius: '0 5px 0 5px' }}
                        />
                    </div>
                  ) : null}
                  {image && (
                    <motion.div className="h-[20rem] w-[18rem]">
                      <img
                        className="object-cover w-full h-full rounded-lg pointer-events-none"
                        src={image || <Skeleton />}
                        alt={alt}
                      />
                    </motion.div>
                  )}
                  <div className="">
                  <div className="flex justify-center">
                      {isVIP && !iAmVip ? (
                          <button
                            className="text-xs w-2/3 px-5 py-2 mx-auto uppercase -translate-y-6 rounded-full  text-secondary"
                            // onClick={() => handleClick(points_required, name, points_required, id)}
                            style={{backgroundColor: color_secondary}}
                          >
                            Uniquement pour les VIP
                          </button>
                       ) : 
                        <button
                        className="w-2/3 px-5 py-2 mx-auto uppercase -translate-y-6 rounded-full  text-secondary"
                        onClick={() => handleClick(points_required, name, points_required, id)}
                        style={{backgroundColor:color_primary}}
                      >
                        Obtenir 
                      </button>
                      }
                  </div>

                    <div className="flex flex-col items-center justify-center pb-2">
                      {name && <h1 className="text-3xl font-bold text-text-main">{name}</h1>}
                      {points_required && <p className="text-xl font-light">{points_required} points</p>}
                    </div>
                  </div>
                </motion.div>
              );
          }) :
              new Array(7).fill(0).map((_, index) => (
                <motion.div className="bg-white border-4 border-white rounded-lg" key={index}>
                  <motion.div className="h-[20rem] w-[18rem]">
                    <motion.div className="h-[20rem] w-[18rem]">
                      <Skeleton 
                        className="object-cover w-full h-full rounded-lg pointer-events-none" 
                        width={'100%'} 
                        baseColor='#eee' 
                        highlightColor='#ddd'
                      />
                    </motion.div>
                  </motion.div>
                  <div className="">
                    <div className="flex justify-center">
                      <button
                        className="w-2/3 px-5 py-2 mx-auto uppercase -translate-y-6 rounded-full bg-primary text-secondary"
                        style={{zIndex: 1000}}
                      >
                        Obtenir
                      </button>
                    </div>

                    <div className="flex flex-col items-center justify-center pb-2">
                      <div className="text-3xl font-bold text-text-main">
                        <Skeleton 
                          width={'150px'} 
                          height={'25px'}
                          baseColor='#eee' 
                          highlightColor='#ddd'
                        />
                      </div>
                      <div className="text-xl font-light">
                        <Skeleton 
                          width={'100px'} 
                          height={'20px'}
                          baseColor='#eee' 
                          highlightColor='#ddd'
                        />
                      </div>
                    </div>
                  </div>
                </motion.div>
              ))
          }
        </motion.div>
        {
          dialogIsOpen && (
            <Dialog
              dialogIsOpen={dialogIsOpen}
              text={`Etes-vous sûr de vouloir dépenser ${pointsValue[0]} points pour obtenir ${pointsValue[1]} ?`}
              handleClose={() => setDialogIsOpen(false)}
              handleCreate={handleCreate}
              pointsRequired={pointsValue[2]}
              displayPoints={displayPoints}
            />
          )
        }
      </motion.div>
      {loading && (
        <Backdrop className={classes.backdrop} open>
           <CircularProgress color='inherit' />
        </Backdrop>
        )}
    </SkeletonTheme>
  );
};

export default SliderImages;
