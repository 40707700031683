import React, { useState, useEffect, useRef } from 'react';
import { motion, useAnimation, useMotionValue } from 'framer-motion';
import CtmChart from './Chart';
import { WheelGameService } from '../_services/wheel-game.service';
import AnimatedPages from '../components/AnimatedPages';
import Confetti from 'react-confetti';
import diacritics from 'diacritics';

const CARDS = [
  { name: '1', deg: 1, color: '#FF6347' },
  { name: '2', deg: 1, color: '#800080' },
  { name: '3', deg: 1, color: '#6A5ACD' },
  { name: '4', deg: 1, color: '#4B0082' },
  { name: '5', deg: 1, color: '#9932CC' },
  { name: '6', deg: 1, color: '#D8BFD8' },
  { name: '7', deg: 1, color: '#FF1493' },
  { name: '8', deg: 1, color: '#FF1493' },
];

const SpinWheel = ({ wheelOptions, utkai, utkaiType, spinTheWheel, handleSubmit, logo,theme_screen1_button_color }) => {
  const pulseControl = useAnimation();
  const rotateControl = useAnimation();
  const isAnimationStartRef = useRef(false);
  const [cards, setCards] = useState(CARDS);
  const [endValue, setEndValue] = useState(0);

  const [endValuePositionName, setEndValuePositionName] = useState(null);
  const [popupMessage, setPopupMessage] = useState('');
  const [showPopup, setShowPopup] = useState(false);

  const removeApostrophes = (inputString) => {
    if (!inputString || typeof inputString !== 'string') {
      return inputString;
    }
    let stringWithoutApostrophes = inputString.replace(/'/g, '');
    stringWithoutApostrophes = diacritics.remove(stringWithoutApostrophes);
    return stringWithoutApostrophes;
  };

  useEffect(() => {
    setEndValuePositionName(removeApostrophes(utkai));
    setCards(
      wheelOptions.map((option, index) => ({
        name: removeApostrophes(option?.name),
        deg: 1,
        color: option?.color,
        range: [(360 / wheelOptions?.length) * index, (360 / wheelOptions?.length) * (index + 1)],
        type: option?.type
      }))
    );
  }, [wheelOptions]);

  useEffect(() => {
    if (cards.length > 0 && endValuePositionName) {
      const selectedCard = cards.find(({ name }) => name === removeApostrophes(endValuePositionName));
      if (selectedCard && selectedCard.range) {
        setEndValue(() => {
          const range = selectedCard.range;
          return -getRandomNumber(range) - 360 * spinCount;
        });
      }
    }
  }, [cards, endValuePositionName]);

  useEffect(() => {
    pulseControl.start(vPulse);
  }, []);

  const getRandomNumber = (range) => {
    const min = range[0];
    const max = range[1];
    const ranges = max - min - 2;
    const randomNumber = Math.floor(Math.random() * ranges) + min + 1;
    return randomNumber;
  };

  const spinCount = 20;

  const vPulse = {
    scale: [0.95, 1.1],
    transition: { duration: 0.6, repeat: Infinity },
  };

  const rotateV = {
    start: ({ rotate }) => ({
      rotate: rotate,
      transition: { type: 'tween', duration: 8, ease: [0.5, 0.1, 0.15, 1] },
    }),
    stop: ({ endDeg }) => ({
      rotate: endDeg,
      transition: { type: 'tween', duration: 0 },
    }),
  };

  const onAnimationStart = () => {
    if (!isAnimationStartRef.current) {
      isAnimationStartRef.current = true;
      rotateControl.start('start');
      pulseControl.stop();
    }

    setTimeout(() => {
      rotateControl.start('stop');
      pulseControl.start(vPulse);
      isAnimationStartRef.current = false;

      setPopupMessage(`Vous avez gagné ${removeApostrophes(endValuePositionName)}`);
      setShowPopup(true);
    }, 8300);
  };

  useEffect(() => {
    pulseControl.start(vPulse);
  }, []);

  const [WheelIsSpinned, setWheelIsSpinned] = useState(false);
  useEffect(() => {
    if (!WheelIsSpinned && spinTheWheel) {
      setWheelIsSpinned(true);
      onAnimationStart();
      playAudio();
      document.getElementById('root').style.overflow = 'hidden';
    }
  }, [spinTheWheel]);

  const playAudio = async () => {
    const audioSrc = "/audio/wheel1.mp3";

    const audioContext = new (window.AudioContext || window.webkitAudioContext)();
    const audioElement = new Audio(audioSrc);
    const audioNode = audioContext.createMediaElementSource(audioElement);

    audioNode.connect(audioContext.destination);
    try {
        await audioContext.resume();
        await audioElement.play();
        console.log('Audio playing');
    } catch (err) {
        console.log('Failed to play audio', err);
    }
  }

  const Popup = ({ message, utkaiType }) => {

    return (
        <section
          style={{
            width: '100vw',
            height: '100vh',
            background: '#fff',
            position: 'fixed',
            top: 0,
            left: 0,
            zIndex: 999,
            display: 'flex',
            flexDirection: 'column',
            alignItems: 'center',
            justifyContent: 'center',
            overflow: 'hidden'
          }}
        >

         {utkaiType === 'lose' ? null : utkaiType !== 'lose' && <Confetti />}
          <div className="flex flex-col items-center justify-center h-screen space-y-4 text-text-main text-center px-5">
            {utkaiType === 'lose' ? (
              <>
                <h1 className="font-bold text-7xl h1">Malheureusement!</h1>
                <motion.p
                  initial={{ opacity: 0, scale: 0 }}
                  animate={{ opacity: 10, scale: 1 }}
                  transition={{ type: 'spring', delay: 0.3 }}
                  className="font-bold text-5xl text-primary"
                  // style={{color:theme_screen1_button_color}}
                  style={{color:'red'}}
                  
                >
                  Vous avez perdu !
                </motion.p>
              </>
            ) : (
              <>
                <h1 className="font-bold text-5xl">Toutes nos félicitations !</h1>
                <motion.p
                  initial={{ opacity: 0, scale: 0 }}
                  animate={{ opacity: 10, scale: 1 }}
                  transition={{ type: 'spring', delay: 0.3 }}
                  className="font-bold text-7xl text-primary"
                  style={{color:theme_screen1_button_color}}
                >
                  {message}
                </motion.p>
                <p className="text-4xl font-light font-bold text-screen3SubTitle">
                  {utkaiType === 'points'
                    ? ''
                    : "Tu vas recevoir un SMS. Montre-le au vendeur pour qu'il te récompenser !"}
                </p>
              </>
            )}
          </div>
        </section>
    );
  };

  return (
    <div className="flex items-center justify-center" style={{ position: 'relative'}}>
      <div>
        <motion.div
          className="wheel_circle_logo absolute z-40 rounded-full flex justify-center items-center cursor-pointer"
          onClick={() => handleSubmit()}
          style={
            isAnimationStartRef.current
              ? {
                  pointerEvents: 'none',
                  backgroundImage: 'radial-gradient(rgb(240, 135, 88) 0%, rgb(229, 103, 47) 40%)',
                  top: '50%',
                  left: '50%',
                  transform: 'translate(-50%, -50%)',
                }
              : {
                  pointerEvents: 'auto',
                  backgroundImage: 'radial-gradient(rgb(240, 135, 88) 0%, rgb(229, 103, 47) 40%)',
                  top: '50%',
                  left: '50%',
                  transform: 'translate(-50%, -50%)',
                }
          }
        >
        <motion.div className="text-2xl font-bold tracking-wide text-white whitespace-nowrap -mt-1" animate={pulseControl}>
          {logo ?
            <img
              src={logo}
              style={{ margin: '0 auto' }}
              alt="Logo"
              className="logo-wheel" 
            />
            :
            <img
              src="/images/logo.png"
              alt="Default Logo"
              style={{ margin: '0 auto' }}
              className="default-logo"
            />
          }
        </motion.div>

        </motion.div>
        <motion.div
          custom={{
            rotate: [0, endValue],
            endDeg: endValue,
          }}
          animate={rotateControl}
          variants={rotateV}
        >
          <CtmChart CARDS={cards} />
        </motion.div>
        <div className="absolute z-10 w-24 h-24 wheel_circle_arrow" style={{
          left: '50%',
          transform: 'rotate(-90deg) translate(0%, -50%)',
        }}>
          <img src="/images/left-arrow-icon-free-svg-file.png" className="object-cover w-full h-full" alt="" />
        </div>
      </div>
      {showPopup && <Popup message={popupMessage} utkaiType={utkaiType} />}
    </div>
  );
};

export default SpinWheel;
