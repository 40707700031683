import React, { useEffect, useRef, useState } from 'react';
import { motion } from 'framer-motion';
import Dialog from './Dialog';
import Skeleton, { SkeletonTheme } from 'react-loading-skeleton';
import 'react-loading-skeleton/dist/skeleton.css';
import { RewardsService } from '../_services/rewards.service';
import { useNavigate } from 'react-router';
import CircularProgress from '@material-ui/core/CircularProgress';
import { makeStyles } from '@material-ui/core/styles';
import Backdrop from '@material-ui/core/Backdrop';

const useStyles = makeStyles((theme) => ({
  backdrop: {
    zIndex: theme.zIndex.drawer + 1,
    color: '#fff',
  },
}));

const SliderImagesHome = ({ type ,data, displayPoints, parentLoading = false,color_primary }) => {

  const classes = useStyles();


  const [loading, setLoading] = useState(false);
  const [dialogIsOpen, setDialogIsOpen] = useState(false);
  const [width, setWidth] = useState(0);
  const [pointsValue, setPointsValue] = useState([]);
  const carousel = useRef();
  const navigate = useNavigate();

  const handleClick = (value, title, points_required, id) => {
    setDialogIsOpen(true);
    setPointsValue([value, title, points_required, id]);
  };

  useEffect(() => {
    setWidth(carousel.current.scrollWidth - carousel.current.offsetWidth);
  }, []);

  const [kioskphone, setKioskPhone] = useState(null);

  useEffect(() => {
    const storedPhone = localStorage.getItem('tablette_client');
    if (storedPhone) {
      setKioskPhone(JSON.parse(storedPhone));
    }
  }, []);

  const handleCreate = () => {
    setLoading(true);
    
    let data = {
      reward_id: pointsValue[3] ?? null,
      client_id: kioskphone.id ?? null,
    }

    RewardsService.Create(data)
      .then((response) => {
        //UPADE tablette_client LOCAL STORAGE
        localStorage.setItem('tablette_client', JSON.stringify(response.client));
        localStorage.setItem('kiosk_purchased_item', JSON.stringify(response.client_rewards_purchased));
        navigate('/receiveGift');
      })
      .catch((error) => {
        console.error(error);
      })
      .finally(() => {
        setLoading(false);
      });
  };
  const handleLoginClick = () => {
    navigate('/input');
  };
  return (
    <SkeletonTheme baseColor="#202020" highlightColor="#444">
      <motion.div className="px-3 overflow-hidden cursor-grab" ref={carousel} whileTap={{ cursor: 'grabbing' }}>
        <motion.div className="scroll-container flex space-x-7" drag="x" dragConstraints={{ right: 0, left: -width }}>
          {
            data.map(({ image, alt, id, name, points_required, client_type_id }) => (
              
              <motion.div
                className="bg-white border-4 border-white rounded-lg relative"
                key={id}
                style={{ opacity: type !== client_type_id ? 0.8 : 1 }}
              >
                {image && (
                  <motion.div className="h-[20rem] w-[18rem]">
                    <img
                      className="object-cover w-full h-full rounded-lg pointer-events-none"
                      src={image || <Skeleton />}
                      alt={alt}
                    />
                  </motion.div>
                )}
                <div className="mt-5">
                  <div className="flex justify-center">
                    <button
                      className={`w-2/3 px-5 py-2 mx-auto uppercase -translate-y-0 rounded-full  text-secondary opacity-70 cursor-not-allowed`}
                      onClick={() => handleLoginClick()}
                      style={{backgroundColor:color_primary}}
                    >
                      Obtenir
                    </button>
                  </div>

                  <div className="flex flex-col items-center justify-center pb-2">
                    {name && <h1 className="text-3xl font-bold text-text-main">{name}</h1>}
                    {points_required && <p className="text-xl font-light">{points_required} points</p>}
                  </div>
                </div>
              </motion.div>
            ))
          }
        </motion.div>
        {
          dialogIsOpen && (
            <Dialog
              dialogIsOpen={dialogIsOpen}
              text={`Etes-vous sûr de vouloir dépenser ${pointsValue[0]} points pour obtenir ${pointsValue[1]} ?`}
              handleClose={() => setDialogIsOpen(false)}
              handleCreate={handleCreate}
              pointsRequired={pointsValue[2]}
              displayPoints={displayPoints}
            />
          )
        }
      </motion.div>
      {loading && (
        <Backdrop className={classes.backdrop} open>
           <CircularProgress color='inherit' />
        </Backdrop>
        )}
    </SkeletonTheme>
  );
};

export default SliderImagesHome;
