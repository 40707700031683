import React, { useEffect, useState } from 'react';
import { RewardsService } from './../_services/rewards.service';
import { AuthService } from './../_services/auth.service';
import { useNavigate } from 'react-router-dom';
import Skeleton, { SkeletonTheme } from 'react-loading-skeleton';
import 'react-loading-skeleton/dist/skeleton.css';
import CircularProgress from '@material-ui/core/CircularProgress';
import Backdrop from '@material-ui/core/Backdrop';
import { makeStyles } from '@material-ui/core/styles';
import ParcoursCrm from '../components/ParcoursCrm';
import SliderImagesHome from '../components/SliderImagesHome';

const useStyles = makeStyles((theme) => ({
  backdrop: {
    zIndex: theme.zIndex.drawer + 1,
    color: '#fff',
  }
}));

const Home = ({ data }) => {
  const [kioskUser, setKioskUser] = useState(null);
  const navigate = useNavigate();
  const classes = useStyles();

  const [ParcoursCrmData, setParcoursCrmData] = useState(null);

  useEffect(() => {
    const storedUser = localStorage.getItem('kiosk_tablette');
    if (storedUser) {
      setKioskUser(JSON.parse(storedUser));
    }

    const parcours_crm = localStorage.getItem('parcours_crm');
    if (parcours_crm) {
      setParcoursCrmData(JSON.parse(parcours_crm));
    }
  }, []);

  const [kioskphone, setKioskPhone] = useState(null);

  useEffect(() => {
    const storedPhone = localStorage.getItem('tablette_client');
    if (storedPhone) {
      setKioskPhone(JSON.parse(storedPhone));
    }else{
      navigate('/');
    }
  }, []);

  // kioskUser
  const { screen_awards_header_title, screen_awards_header_subtitle, screen_awards_points_title, logo, color_primary, color_secondary } =
    kioskUser?.tablette_settings || {};

  // kioskphone
  const { id, points, last_name, client_type_id, visit_id } = kioskphone || {};
  const partner_client_id = id;

  const displayPoints = points ?? 0;

  const [GridLoading, setGridLoading] = useState(true);
  const [rewards, setRewards] = useState([]);

  const getRewards = () => {
    setGridLoading(true);
    RewardsService.getAll()
      .then(res => {
        setRewards(res.rewards);
      })
      .catch(err => {
        console.log(err);
      })
      .finally(() => {
        setGridLoading(false);
      });
  };

  useEffect(() => {
    getRewards();
  }, []);
  const [loading, setLoading] = useState(false);

  const handleLogout = () => {
    setLoading(true);

    AuthService.logout(visit_id)
      .then(() => {
        localStorage.removeItem('tablette_client');
        localStorage.removeItem('kiosk_purchased_item');
        localStorage.removeItem('client_new_points');
        localStorage.removeItem('parcours_crm');
        navigate('/');
      })
      .catch(error => {
        console.log(error);
      })
      .finally(() => {
        setLoading(false);
      });
  };
  const handleLoginClick = () => {
    navigate('/input');
  };
  return (
    <>
      {loading && (
        <Backdrop className={classes.backdrop} open>
          <CircularProgress color='inherit' />
        </Backdrop>
      )}

      <section className="h-screen space-y-6 overflow-hidden">
        <div className="flex justify-between p-4 text-text-main">
          <img src={logo} alt='Logo' className='w-100 h-20 mr-2' />
          <div className='text-center'>
            <h1 className="max-w-6xl font-black sm:text-2xl lg:text-3xl ">
              {screen_awards_header_title} {last_name} !
            </h1>
            <p className="max-w-lg text-2xl font-light">{screen_awards_header_subtitle}</p>
          </div>

          <button
            className="px-8 py-3 text-sm uppercase border-2 rounded-full md:text-2xl border-primary text-primary"
            onClick={handleLoginClick}
            style={{border: '1px solid '+color_primary, color: color_primary}}
          >
            Se connecter
          </button>
        </div>
        <div className="h-full bg-primary" style={{ backgroundColor: color_primary, marginTop: '0px' }}>
          <div className="flex flex-col items-center justify-center p-6 text-secondary">
            <button
              className="py-3 px-10 text-xl font-semibold bg-primary text-secondary rounded-full shadow-lg hover:bg-primary-dark focus:outline-none focus:ring focus:ring-primary"
              onClick={handleLoginClick}
              style={{background: color_secondary}}
            >
              Connectez vous pour accéder a nos récompenses
            </button>
          </div>
          {
            !GridLoading ?
              client_type_id === null || rewards.some(reward => reward.client_type_id === null) ? (
                <SliderImagesHome data={rewards} displayPoints={displayPoints} type={client_type_id} color_primary={color_primary}/>
              ) : (
                ''
              )
            :
              <div>
                <SkeletonTheme baseColor="#ccc" highlightColor="#eee">
                  <div className='flex items-center justify-left'>
                    {
                      new Array(10).fill(0).map((_, index) => (
                        <div key={`HomeSkeleton${index}`} style={{margin: '0 13px'}}>
                          <Skeleton count={1} width={'295px'} height={'500px'} borderRadius={'5px'} />
                        </div>
                      ))
                    }
                  </div>
                </SkeletonTheme>
              </div>
              // <SliderImagesHome parentLoading={GridLoading} />
          }
        </div>
      </section>

      {
        !GridLoading && ParcoursCrmData?
          <ParcoursCrm 
            partner_client_id={partner_client_id}
            parcoursCrmData={ParcoursCrmData} 
            handleClose={() => setParcoursCrmData(null)}
          />
        :
          ''
      }
    </>
  );
};

export default Home;
