import { AnimatePresence } from 'framer-motion';
import Home from './pages/Home.jsx';
import InputNumbers from './pages/InputNumbers.jsx';
import { useLocation, useRoutes, Navigate } from 'react-router-dom';
import { useEffect, useState } from 'react';
import fakeData from './fakeDb/index.json';
import Points from './pages/Points.jsx';
import Welcome from './pages/Welcome.jsx';
import ReceiveGift from './pages/ReceiveGift.jsx';
import Login from './pages/Login.jsx';
import ContestGame from './pages/ContestGame.jsx';
import { useNavigate } from 'react-router-dom';
// import { AuthService } from './_services/auth.service';
import Backdrop from '@material-ui/core/Backdrop';
import CircularProgress from '@material-ui/core/CircularProgress';
import { makeStyles } from '@material-ui/core/styles';

const useStyles = makeStyles((theme) => ({
  backdrop: {
    zIndex: theme.zIndex.drawer + 1,
    color: '#fff',
  }
}));

export default function App() {
  const [data, setData] = useState(fakeData);
  const location = useLocation();
  const navigate = useNavigate();
  const classes = useStyles();
  
  // const stored_kiosk_tablette = JSON.parse(
  //   localStorage.getItem('kiosk_tablette')
  // )
  // if( !stored_kiosk_tablette ){
  //   navigate('/login');
  // }

  //Inactivity Detector
    // let timerId;
    
    // const logout_inactive_delay_in_seconds = stored_kiosk_tablette?.tablette_settings?.logout_inactive_delay_in_seconds

    // const resetTimer = () => {
    //   if( logout_inactive_delay_in_seconds && logout_inactive_delay_in_seconds > 0 ){
    //     clearTimeout(timerId);
    //     timerId = setTimeout(() => {
    //       handleLogout()
    //     }, logout_inactive_delay_in_seconds * 1000);
    //   }
    // };

    // useEffect(() => {
    //   window.addEventListener('mousemove', resetTimer);
    //   window.addEventListener('keydown', resetTimer);
    //   window.addEventListener('scroll', resetTimer);
    //   window.addEventListener('touchstart', resetTimer);

    //   return () => {
    //     window.removeEventListener('mousemove', resetTimer);
    //     window.removeEventListener('keydown', resetTimer);
    //     window.removeEventListener('scroll', resetTimer);
    //     window.removeEventListener('touchstart', resetTimer);
    //   };
    // }, [logout_inactive_delay_in_seconds]);

    // const [Loading, setLoading] = useState(false);

    // const handleLogout = () => {
    //   setLoading(true);
      
    //   const stored_kiosk_tablette_client = JSON.parse(
    //     localStorage.getItem('tablette_client')
    //   )
    //   const partner_client_visit_id = stored_kiosk_tablette_client?.visit_id

    //   AuthService.logout(partner_client_visit_id)
    //     .then(() => {
    //       localStorage.removeItem('tablette_client');
    //       localStorage.removeItem('kiosk_purchased_item');
    //       localStorage.removeItem('client_new_points');
    //       localStorage.removeItem('parcours_crm');
    //       navigate('/');
    //     })
    //     .catch(error => {
    //       console.log(error);
    //     })
    //     .finally(() => {
    //       setLoading(false);
    //     });
    // };
  //

  const routes = useRoutes([
    {
      path: '/login',
      element: <Login data={data} />,
    },
    {
      path: '/*',
      element: <Navigate to='/login' />,
    },

    {
      path: '/',
      element: <Home data={data} />,
    },
    {
      path: '/input',
      element: <InputNumbers data={data} />,
    },
    {
      path: '/points',
      element: <Points data={data} />,
    },
    {
      path: '/welcome',
      element: <Welcome data={data} />,
    },
    {
      path: '/receiveGift',
      element: <ReceiveGift data={data} />,
    },
    {
      path: '/wheel/:wheel_id',
      element: <ContestGame data={data} />,
    },
  ]);

  // if (loading) return <p>loading...</p>;
  // if (error) return <p>{error.message}</p>;
  return (
    <AnimatePresence mode='wait'>
      <main
        key={location.pathname}
        location={location}
      >
        {/* {
          Loading && (
            <Backdrop className={classes.backdrop} open>
              <CircularProgress color='inherit' />
            </Backdrop>
          )
        } */}
        {routes}
      </main>
    </AnimatePresence>
  );
}
