import { motion } from 'framer-motion';
import React from 'react';

const animation = {
  initial: { opacity: 0, y: 100 },
  animate: { opacity: 1, y: 0 },
  exit: { opacity: 0, y: 100 },
};

const AnimatedPages = ({ children, theme_screen1_background_image }) => {
  const backgroundStyle = theme_screen1_background_image
    ? {
        backgroundImage: `url(${theme_screen1_background_image})`,
        backgroundRepeat: "no-repeat",
        backgroundSize: "cover",
        backgroundPosition: "center",
        height: '100%'
      }
    : {
        background: 'linear-gradient(to right, #1067aa, #095da5, #0a71b3, #188ac9, #1160a3, #1798d2, #1395cf)'
      };

  return (
    <motion.div
      variants={animation}
      initial={'initial'}
      animate={'animate'}
      exit={'exit'}
      transition={{ duration: 0.5 }}
      style={backgroundStyle}
    >
      {children}
    </motion.div>
  );
};

export default AnimatedPages;
