import React from 'react';
import { motion } from 'framer-motion';
const Backdrop = ({ children, onClick }) => {
  return (
    <motion.div
      className='absolute top-0 left-0 flex items-center justify-center w-full h-full overflow-hidden bg-black bg-opacity-60'
      onClick={onClick}
    >
      {children}
    </motion.div>
  );
};

export default Backdrop;
